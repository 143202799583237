.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 8px;
    height: 40px;
}

.tags-input:focus {
    border: 1px solid #0052cc;
}

.myInput {
    border: none !important;
    border-color: transparent !important;
}

.myInput:focus {
    outline: transparent;
}

.tag-close-icon {
    display: block;
    width: 14px;
    height: 14px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
}

@media screen and (max-width: 567px) {
    .tags-input {
        width: calc(100vw - 32px);
    }
}
