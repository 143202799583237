.container {
    padding: 60px;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sightlyPanelHeader {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
}

.badge {
    padding: 0.25em 0.5em;
    border-radius: 12px;
    font-size: 0.875em;
}

.hashtags{
    width: 100%;
}

.main-title{
    font-size: 26px;
}

.rs-picker-default .rs-picker-toggle{
    z-index: 0;
}

