.Tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
}

.Tabs__Tab {
    margin-right: 32px;
    color: #6e8399;
    font-size: 14px;
    cursor: pointer;
    height: 56px;
    display: flex;
    text-align: center;
    align-items: center;
    text-decoration: none !important;
}

.Tabs__Tab:hover {
    color: #333d47;
}

.Tabs__Tab--active {
    font-weight: 700;
    color: #333d47 !important;
    position: relative;
    box-shadow: 0 -2px 0 #26aae1 inset;
    display: flex;
    text-align: center;
    align-items: center;
    margin-right: 32px;
    cursor: pointer;
}
