.rs-tree {
	overflow-y: inherit;
	max-height: 500px;
}

.rs-tree-node > .rs-tree-node-label {
	width: 100%;
}

.rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content,
.rs-tree-node-disabled
	> .rs-tree-node-label
	> .rs-tree-node-label-content:hover {
	width: 100%;
}

.rs-picker-toggle-wrapper {
	display: block;
}

.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
	display: block;
}

.rs-form-vertical .rs-form-group .rs-input-group {
	width: auto;
}
