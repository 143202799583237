@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Inter');

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./fonts/Inter-Regular.ttf) format('truetype');
}

* {
    font-family: 'Inter';
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --s-loader-wrapper-bg-color: rgb(21, 22, 43);
    --s-loader-width: 20px;
    --s-loader-height: var(--s-loader-width);
    --s-loader-border-color-trans: var(--sightly-blue);
    --s-loader-border-color-full: transparent;
    --bgColor: #f0f3f5;
    --purpleColor: #5b32ef;
    --sightly-blue: #00aeef;
    --sightly-blue-light: #bfe1fb;
    --sightly-slate: #333d47;
    --lightPurple: rgba(123, 97, 255, 0.3);
}

@layer utilities {
    .text-sightly-slate {
        color: var(--sightly-slate);
    }
    .text-sightly-blue-light {
        color: var(--sightly-blue-light);
    }
    .text-sightly-blue {
        color: var(--sightly-blue);
    }
    .border-sightly-blue-light {
        border-color: var(--sightly-blue-light);
    }
    .bg-sightly-blue {
        background-color: var(--sightly-blue);
    }
    .bg-sightly-blue-light {
        background-color: var(--sightly-blue-light);
    }

    .bg-sightly-purple {
        background-color: var(--purpleColor);
    }
    .bg-form {
        background-color: #f5f5f5;
    }
    .fill-sightly-blue {
        fill: var(--sightly-blue);
    }
    .border-sightly-blue {
        border: 1px solid var(--sightly-blue);
    }
    .border-sightly-blue-thick {
        border: 2px solid var(--sightly-blue);
    }
    .border-bottom-sightly-blue {
        border-bottom: 2px solid var(--sightly-blue);
    }

    .ring-sightly-blue {
        --tw-ring-color: var(--sightly-blue);
    }

    .ring-sightly-purple {
        --tw-ring-color: var(--purpleColor);
    }
}

/* Scroll container styling is too tall */
#moments-scroll-container {
    height: 62vh !important
}

#moments-results-container {
    overflow-y: hidden;
    overflow-x: auto;
}

.sc-hRJfrW{
    background-color: white;
}

.focused-item {
    background-color: #c0ecfe;
    position: relative;
    border-radius: 4px;
}

.focused-item::after {
    background-color: #5aa83e;
    content: 'New';
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    border-radius: 8px;
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.sightlyDropdownItem:hover {
    background-color: #c0ecfe;
    color: #1da1f2;
}

.sightlyNotification:hover {
    background-color: #c0ecfe;
}

.sightlyDropdownItem {
    /* transition: background-color 0.2s linear; */
    background-color: #f5f5f5;
    color: #333d47;
}

.trendActionFilters:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #6e8399, #6e8399);
}

*.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sightlyLink:hover {
    color: var(--sightly-blue) !important;
    cursor: pointer;
    text-decoration: underline;
}

.sightlyLinkActive {
    color: var(--sightly-blue) !important;
    cursor: pointer;
    text-decoration: underline;
}

.sightlyNavBar {
  background-color: #F0F1F3;
}

.navItemActive {
  background-color: rgba(38, 170, 225, 0.1);
  color: #004C6B;
}

.navItemParentActive {
  color: #004C6B;
}

.navItem:hover {
  background-color: #F5F5F5;
}

.navItemScrollHide {
    scrollbar-width: none;
}
.navItemScrollHide::-webkit-scrollbar-track {
    background: transparent;
}
.navItemScrollHide::-webkit-scrollbar {
    width: 0;
}

.social-summary-border-wrapper {
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    padding: 3px;
    background: linear-gradient(to right, #48C294, #8F7EFF, #4F46E5);
}

.social-summary-border-inner {
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    width: 100%;
}

.tippy-box[data-theme~='light'] {
    background-color: white;
    color: #414141;
}

.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: white;
}
.tippy-box[data-theme~='light'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: white;
}
.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: white;
}
.tippy-box[data-theme~='light'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: white;
}

.catRow:hover {
    background-color: #f2faff;
    transition: background-color 0.4s linear;
}

.catRow {
    border-bottom: 1px solid #f2f2f5;
}

.catCell {
    padding: 10px;
    width: 100%;
}

.catHeader {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.66666667;
    color: #8e8e93;
    border-bottom: 1px solid #f2f2f5;
}

.sightly_modal__wrap {
    position: fixed;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow-x: hidden;
    background-color: rgba(245, 245, 245, 0.5);
    pointer-events: none;
    opacity: 0;
    transition: opacity 100ms 0ms ease;
}

.sightly_visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 0s ease-in-out;
}

.sightly_modal {
    position: relative;
    display: block;
    border-radius: 12px;
    background-color: #fff;
    align-self: center;
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 0ms ease, transform 300ms 0ms ease;
}

.sightly_visible .sightly_modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 0ms ease, transform 250ms 0ms ease;
}

.maxOneLine {
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.maxTwoLines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.maxThreeLines {
    /* word-wrap: normal !important; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.maxFourLines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.rs-steps-vertical .rs-steps-item {
    padding-bottom: 5px !important;
}

div.rs-calendar-table-cell.rs-calendar-table-cell-selected-start.rs-calendar-table-cell-selected {
    color: #D8BFD8 !important;
}

.spinning-loader {
    width: var(--s-loader-width);
    height: var(--s-loader-height);
    border: 2px solid var(--s-loader-border-color-trans);
    border-left-color: var(--s-loader-border-color-full);
    border-radius: 50%;
    background: transparent;
    animation-name: rotate-s-loader;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: linear;
    position: relative;
}

.stationary-loader {
    width: var(--s-loader-width);
    height: var(--s-loader-height);
    border: 2px solid var(--s-loader-border-color-trans);
    border-color: var(--s-loader-border-color-full);
    border-radius: 50%;
    background: transparent;
    position: relative;
}

@keyframes rotate-s-loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.rs-tag {
    background-color: var(--sightly-blue) !important;
    color: white !important;
    font-weight: 600 !important;
}

.rs-picker-value-list {
    color: var(--sightly-blue);
}

.rs-picker-value-count {
    background-color: var(--sightly-blue) !important;
}

.rs-picker-menu {
    z-index: 999999 !important;
}

.hoverActive:hover {
    background-color: #f2f2f5;
    border-radius: 45px;
}

.hoverWhite:hover {
    background-color: white;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--sightly-blue) !important;
    /* font-weight: 500; */
}

.input-label {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #696969;
    margin-bottom: 8px;
    text-align: left;
}

.input-label.required::after {
    content: '*';
    color: red;
}

.required:after {
    content: ' *';
    color: red;
}

.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    border-color: var(--sightly-blue) !important;
    background-color: var(--sightly-blue) !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: var(--sightly-blue) !important;
    background-color: var(--sightly-blue) !important;
}

.rs-pagination > li.rs-pagination-btn-active > a {
    color: var(--sightly-blue) !important;
}

.sightlyPanelHeader {
    color: #575757;
    font-weight: 600;
    font-size: 24px;
}

.rs-control-label {
    font-size: 16px;
}

.sightly-informational-font {
    color: #a3a3a3;
    font-size: 12px;
    margin-top: 24px;
    font-weight: 500;
}

.descriptionText {
    color: #a3a3a3;
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: 500;
}

.sightly-divider {
    height: 1px;
    background-color: #d4d9d9;
    opacity: 0.5;
}

.scenarioRow {
    display: flex;
}

.rs-picker-menu .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
    overflow: visible !important;
}

.brand-profile-topics-tree .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
    width: 100%;
    height: 38px;
    border: solid 1px #ebedf0;
}

.rs-picker-disabled {
    opacity: 1 !important;
}

.rs-table-cell-content {
    display: flex;
    align-items: center;
}

.no-padding-modal .rs-modal-content{
    padding: 0;
    align-self: center;
}

.shimmer-wrapper {
    position: relative;
    overflow: hidden;
    background-color: #f6f7f8;
    border-radius: 4px;
}

.shimmer {
     color: #F9F8F8;
     display: inline-block;
     -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
     background-repeat: no-repeat;
     animation: shimmer 2.5s infinite;
 }

 @keyframes shimmer {
     100% {
         -webkit-mask-position: left
     }
 }

@keyframes blink {
    50% {
        color: transparent;
    }
}
.loader__dot {
    animation: 1s blink infinite;
}
.loader__dot:nth-child(2) {
    animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
    animation-delay: 500ms;
}

.collapse {
    visibility: visible !important;
}

.rs-picker-default .rs-picker-toggle {
    border: 1px solid lightgrey !important;
    /* height: 38px !important; */
}

iframe[name='JSD widget'] {
    display: block;
    left: 15px;
    bottom: -10px !important;
    opacity: 1;
    transform: scale(0.8);
}
iframe[name='JSD widget']:hover {
    opacity: 1;
}

.rs-table-pagination-toolbar {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

/* Required custom CSS for rsuite Timeline component*/
.custom-timeline {
    margin-left: 20px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: #fff;
    top: 0;
    left: -2px;
    border: 2px solid #ddd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
}

.popup-box {
    position: absolute;
    z-index: 1;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    left: 200px;
  }

  .rs-picker-toolbar-right-btn-ok {
    background-color: #7748F9 !important;
}

.rs-picker-toolbar-option, .rs-picker-toolbar-option:hover, .rs-picker-toolbar-option:focus, .rs-picker-toolbar-option:active {
    color: #7748F9 !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    border-color: #7748F9 !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #7748F9 !important;
}
.rs-calendar-table-cell-in-range::before {
    background-color: var(--lightPurple) !important;
}
.rs-calendar-table-cell-content:hover {
    background-color: #7748F9 !important;
    color: white !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #7748F9 !important;
}

.custom-input-editable-report-name[type='text']:focus {
    --tw-ring-color: transparent;
  }

.custom-input-editable-segment-name:focus {
    --tw-ring-color: transparent !important;
    border: none !important;
  }

.custom-input-editable-segment-description:focus {
    --tw-ring-color: transparent !important;
}

.custom-tab-active-color .Tabs__Tab--active {
    box-shadow: 0 -2px 0 #7B61FF inset; /* New color for this view only */
}
.custom-tag-picker-color .rs-tag{
    background-color: #7B61FF !important ;
}

.custom-tag-picker-color .rs-picker-value-count {
    background-color: #7B61FF !important ;
}

.custom-tag-picker-color .rs-picker-value-list {
    color: #7B61FF !important ;
}

/*
** Commented lines may be needed in the future; that's why they are not deleted.
*/
/* .custom-tag-picker-color-checked .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.custom-tag-picker-color-checked .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #7B61FF !important ;
    background-color: #7B61FF !important ;
}

.custom-radio-color-checked  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    border-color: #7B61FF !important ;
    background-color: #7B61FF !important ;
} */

.custom-radio-margin .rs-radio-inline{
    margin-left: 0px
}

.custom-tabs-activation-report > .Tabs {
    flex-wrap: nowrap;
}

.scrollable-container {
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .scrollable-container::-webkit-scrollbar { 
    display: none; 
  }

input[type="time"]::-webkit-calendar-picker-indicator {
    background: url('./assets/img/AI/justification/clockIcon.svg') no-repeat center;
    filter: invert(0%) sepia(0%) saturate(0%) brightness(60%) contrast(100%);    
    background-size: contain;
    width: 12px;
    height: 12px;
    cursor: pointer;
}