.ai_generate_modal_root  .rs-modal-header-close {
  position: absolute; /* Makes the button positioning relative to the nearest positioned ancestor */
  top: 2px; /* Distance from the top of the modal content */
  right: 8px; /* Distance from the right of the modal content */
  z-index: 1051; /* Ensure it's above other modal content (rsuite's modal z-index is usually around 1050) */
}

.ai_generate_modal_root  .rs-modal-content {
  background: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
}
